import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { CONTENT } from './cms/content';
import { getPageBySlug } from './cms/selectors';
import ScrollToTop from './ScrollToTop';
import Home from './pages/Home';
import SeriesRouter from './routers/SeriesRouter';
import GalleryRouter from './routers/GalleryRouter';
import SimpleTextPage from './pages/SimpleTextPage';
import WritingPage from './pages/WritingPage';
import ContactPage from './pages/ContactPage';
import MentoringPage from "./pages/MentoringPage";
import PodcastPage from "./pages/PodcastPage";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/writing" exact={true}>
          <WritingPage page={getPageBySlug(CONTENT.pages, "writing")} />
        </Route>
        <Route path="/bio" exact={true}>
          <SimpleTextPage page={getPageBySlug(CONTENT.pages, "bio")} />
        </Route>
        <Route path="/mentoring" exact={true}>
          <MentoringPage page={getPageBySlug(CONTENT.pages, "mentoring")} />
        </Route>
        <Route path="/podcast" exact={true}>
          <PodcastPage page={getPageBySlug(CONTENT.pages, "podcast")} />
        </Route>
        <Route path="/contact" exact={true}>
          <ContactPage page={getPageBySlug(CONTENT.pages, "contact")} />
        </Route>
        <Route path="/series">
          <SeriesRouter allSeries={CONTENT.series} />
        </Route>
        <Route path="/current/drawing" exact={true}>
          <GalleryRouter key={"current-drawing"} allSeries={CONTENT.series} type={"Drawing"} title={"Current Drawings"} current={true} />
        </Route>
        <Route path="/archive/drawing" exact={true}>
          <GalleryRouter key={"archive-drawing"} allSeries={CONTENT.series} type={"Drawing"} title={"Archive Drawings"} current={false} />
        </Route>
        <Route path="/current/painting" exact={true}>
          <GalleryRouter key={"current-painting"} allSeries={CONTENT.series} type={"Painting"} title={"Current Paintings"} current={true} />
        </Route>
        <Route path="/archive/painting" exact={true}>
          <GalleryRouter key={"archive-painting"} allSeries={CONTENT.series} type={"Painting"} title={"Archive Paintings"} current={false} />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
