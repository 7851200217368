import { Page } from '../cms/schema';
import TextPage from './TextPage';

type SimpleTextPageProps = {
    page: Page | undefined
}

const SimpleTextPage = ({ page }: SimpleTextPageProps): JSX.Element => {
    return (
        <TextPage key={page?.slug}>
            <h2>{page?.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: page?.content ? page.content : "" }}>
            </div>
        </TextPage>
    )
}

export default SimpleTextPage