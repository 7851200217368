import { FunctionComponent } from "react";
import styled from 'styled-components';
import Container from '../pages/fragments/Container';

const StyledTextPageContainer = styled.main`
  min-height: 68vh;

  h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 20px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
  }

  p {
    font-size: 13px;
    line-height: 30px;
    padding-bottom: 13px;
  }
`

const TextPage: FunctionComponent = ({ children }) => {
  return (
    <Container>
      <StyledTextPageContainer>
        {children}
      </StyledTextPageContainer>
    </Container>)
}

export default TextPage