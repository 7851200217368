
import { FunctionComponent } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const StyledSubMenuItem = styled.li`
    font-size: 1em;
    display: inline;
    :hover {
        cursor: pointer;
    }
    span:before {
        content: "-";
        color: #fff;
        font-weight: bold;
    }
`

type SubMenuItemProps = {
    text: string
    path: string
}

const SubMenuItem: FunctionComponent<SubMenuItemProps> = ({ text, path }: SubMenuItemProps) => {
    const match = useRouteMatch({
        path: path,
        exact: true
    });
    return (
        <StyledSubMenuItem>{match ? <span onClick={() => window.location.reload()}>{text}</span> : <Link to={path}><span>{text}</span></Link>}</StyledSubMenuItem>
    )
}

export default SubMenuItem