
import { FunctionComponent } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const StyledMenuItem = styled.li`
    font-size: 2em;
    :hover {
        cursor: pointer;
    }
`

type MenuItemProps = {
    text: string
    path: string
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ text, path }: MenuItemProps) => {
    const match = useRouteMatch({
        path: path,
        exact: true
    });
    return (
        <StyledMenuItem>{match ? <span onClick={() => window.location.reload()}>{text}</span> : <Link to={path}><span>{text}</span></Link>}</StyledMenuItem>
    )
}

export default MenuItem