import styled from 'styled-components';
import { Page } from '../cms/schema';
import TextPage from './TextPage';
import ResponsiveImage from '../components/ResponsiveImage/ResponsiveImage';

const StyledText = styled.div`
    margin-top: 20px;
`

type WritingPageProps = {
    page: Page | undefined
}

const WritingPage = ({ page }: WritingPageProps): JSX.Element => {
    return (
        <TextPage key={"writing"}>
            <h2>{page?.name}</h2>
            {page?.image &&
                <ResponsiveImage width={100} image={page?.image} caption={page.imageCaption} />}
            <StyledText dangerouslySetInnerHTML={{ __html: page?.content ? page.content : "" }}>
            </StyledText>
        </TextPage >
    )
}

export default WritingPage