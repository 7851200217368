import React from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import { Page } from '../cms/schema';
import TextPage from './TextPage';

type PodcastEpisode = {
    number: number,
    title: string,
    soundcloudEmbeddUri: string
}

const podcastEpisodes: Array<PodcastEpisode> = [
    {
        number: 1,
        title: "Context",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1178292271&color=%23797272&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
    {
        number: 2,
        title: "Two Kinds of Thinking",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1203595759&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
    {
        number: 3,
        title: "The Body Draws",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1227380668&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
    {
        number: 4,
        title: "Drawing and Language",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258169449&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
    {
        number: 5,
        title: "Drawing Workshop",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1303112209&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
    {
        number: 6,
        title: "Seeing Otherwise",
        soundcloudEmbeddUri: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1333883332&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    },
];

const spotifyUris: Array<string> = [
    "https://open.spotify.com/embed/episode/768xjYKHOGHmFYgY11JuVX",
    "https://open.spotify.com/embed/episode/55pJ2UXhSS6QdY2dkdmssZ",
    "https://open.spotify.com/embed/episode/3tqnO7INFRS9TiiVHK3KKl",
    "https://open.spotify.com/embed/episode/419qixbx7tZGpIiTEnQi1s",
    "https://open.spotify.com/embed/episode/3Yond99ZRwDXtn3hakgdi5",
    "https://open.spotify.com/embed/episode/4RvaOa6IfVOaaxs9QI9LON",
]

type PodcastPageProps = {
    page: Page | undefined
}

const StyledPodcastButtons = styled.div`
    margin-bottom: 20px;
    display: flex;
    ${down('lg')} {
        align-items: center;
        justify-content: center;
    }
`

const StyledApplePodcastButton = styled.img`
    ${down('lg')} {
        margin-right: 10px;
    }
`

const StyledApplePodcastLink = styled.a`
    text-decoration: none;
    margin: 0;
`

const StyledSpotifyButton = styled.img`
    ${up('lg')} {
        margin-left: 20px;
    }
`

const StyledSpotifyLink = styled.a`
    text-decoration: none;
    margin: 0;
`


const StyledArtsCouncilLink = styled.a`
    text-decoration: none;
    margin: 0;
`

const StyledArtsCouncilLogo = styled.img`
    margin-top: 30px;
    margin-left: -15px;
    height: 70px;
    float: left;
    top: 50%;
    position: relative;
`

const ShowContent = styled.div`
    margin-top: 30px;
    h2 {
        padding-bottom: 0;
    }
`

const StyledShowVideoContainer = styled.div`
    h2 {
        margin-top: 30px;
    }
    p {
        margin-top: -10px;
    }
`

const VideoWrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const StyledShowInviteImage = styled.img`
    width: 100%;
`

const StyledShowInvitation = styled.div`
    margin-top: 50px;
`

const StyledShowImage = styled.img`
    width: 100%;
    margin-top: 10px;
`

const ListenButtons = (): JSX.Element => {
    return (
        <StyledPodcastButtons>
            <StyledApplePodcastLink href="https://podcasts.apple.com/se/podcast/processing-trauma-through-drawing/id1644518443?l=en" target="_blank" rel="noreferrer">
                <StyledApplePodcastButton src="/apple_podcast_button.svg" />
            </StyledApplePodcastLink>

            <StyledSpotifyLink href="https://open.spotify.com/show/0VX0O59FAdQ06OyRyDfswf" target="_blank" rel="noreferrer">
                <StyledSpotifyButton src="/spotify_podcast_button.svg" />
            </StyledSpotifyLink>
        </StyledPodcastButtons>
    )
}

const PodcastEpisodeList = (): JSX.Element => {
    const podcastEpisodePlayers = podcastEpisodes.map(podcastEpisode => {
        return (<React.Fragment>
            <h3>Episode {podcastEpisode.number} <i>{podcastEpisode.title}</i></h3>
            <iframe title={podcastEpisode.title} width="100%" height="100" scrolling="no" frameBorder="no" allow="autoplay" src={podcastEpisode.soundcloudEmbeddUri}></iframe>
        </React.Fragment>)
    })

    return (
        <React.Fragment>
            {podcastEpisodePlayers}
        </React.Fragment>
    )
}

const SpotifyEpisodePlayers = (): JSX.Element => {
    const podcastEpisodePlayers = spotifyUris.map((spotifyUri, index) => {
        return (
            <React.Fragment>
                <iframe title={index.toString()} style={{ borderRadius: '12px' }} src={spotifyUri} width="100%" height="152" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </React.Fragment>
        )
    })
    return (
        <React.Fragment>
            {podcastEpisodePlayers}
        </React.Fragment>
    )
}

const ShowInvitation = (): JSX.Element => {
    return (
        <StyledShowInvitation>
            <StyledShowInviteImage srcSet='/images/show2022/invitation_320.jpg 320w, /images/show2022/invitation_640.jpg 640w, /images/show2022/invitation_1280.jpg 1280w, /images/show2022/invitation_1920.jpg 1920w'
                sizes="(max-width: 600px) 480px, 800px"
                src="/images/show2022/invitation_1280.jpg"
                alt="Making Good: Drawing and the Processing of Trauma" />
        </StyledShowInvitation>
    )
}

const ShowVideo = (): JSX.Element => {
    return (
        <StyledShowVideoContainer>
            <h2>Dialogue</h2>
            <p>with Monika Kita discussing the Making Good Project and her film <i>Anamnesis</i></p>
            <VideoWrapper>
                <iframe src="https://www.youtube.com/embed/sACQFQbYIWE?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </VideoWrapper>
        </StyledShowVideoContainer>
    )
}

const ShowImage = (): JSX.Element => {
    return (
        <>
            <StyledShowImage srcSet='/images/show2022/making_good_320.jpg 320w, /images/show2022/making_good_640.jpg 640w, /images/show2022/making_good_1280.jpg 1280w, /images/show2022/making_good_1920.jpg 1920w'
                sizes="(max-width: 600px) 480px, 800px"
                src="/images/show2022/making_good__1280.jpg"
                alt="???" />
        </>
    )
}

const Show = (): JSX.Element => {
    return (
        <ShowContent>
            <h2>Exhibition</h2>
            <ShowImage />
            <ShowVideo />
            <ShowInvitation />
        </ShowContent>
    )
}

const ArtCouncilLogo = (): JSX.Element => {
    return (
        <StyledArtsCouncilLink href="https://www.artscouncil.org.uk" target="_blank" rel="noreferrer">
            <StyledArtsCouncilLogo src="/arts_council_logo.jpg" alt="Arts Council" /></StyledArtsCouncilLink>
    )
}

const PodcastPage = ({ page }: PodcastPageProps): JSX.Element => {
    return (
        <TextPage key={"podcast"}>
            <div dangerouslySetInnerHTML={{ __html: page?.content ? page.content : "" }}></div>

            <ListenButtons />

            <PodcastEpisodeList />

            <Show />

            {/* <ArtCouncilLogo /> */}
        </TextPage >
    )
}

export default PodcastPage
