import { FunctionComponent } from "react";
import styled from 'styled-components';
import ImageCarousel, { Image } from '../components/ImageCarousel/ImageCarousel';
import { Piece, Series } from '../cms/schema';
import Container from '../pages/fragments/Container';

const StyledSeriesCopy = styled.div`
    text-align: left;
    margin-top: 60px;

    h2 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 300;
        padding-top: 20px;
    }

    p {
        font-size: 13px;
        line-height: 30px;
        padding-bottom: 13px;
    }
`

const piecesToImages = (pieces: Array<Piece>): Array<Image> => {
    const captionForPiece = (piece: Piece): JSX.Element => {
        const { title, year, medium, dimensions } = piece;
        return (<span><i>{title}</i> {year} {medium} {dimensions}</span>);
    }
    const altTagForPiece = (piece: Piece): string => {
        const { title, year, medium, dimensions } = piece;
        return `${title} ${year} ${medium} ${dimensions}`;
    }

    return pieces.flatMap(piece => {
        if (!piece.image) {
            return [];
        }
        return {
            fallbackImageUrl: piece.image.fallbackImageUrl,
            imageSizes: piece.image?.imageSizes,
            caption: captionForPiece(piece),
            alt: altTagForPiece(piece),
        }
    });
}

type SeriesPageProps = {
    series: Series
}

const SeriesPage: FunctionComponent<SeriesPageProps> = ({ series }: SeriesPageProps) => {
    const images: Array<Image> = piecesToImages(series.pieces);

    return (
        <Container>
            <ImageCarousel images={images} />
            <StyledSeriesCopy dangerouslySetInnerHTML={{ __html: series.copy }}></StyledSeriesCopy>
        </Container>
    )
}

export default SeriesPage