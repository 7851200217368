import { FunctionComponent } from 'react';
import styled from 'styled-components';
import MenuItems from './MenuItems';
import img from './menu.jpg';

interface StyledMenuProps {
  open: boolean
}

const StyledMenu = styled.nav<StyledMenuProps>`
  background: url(${img}) no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ open }) => open ? '100vh' : '0'};
  z-index: 3;
  transition: 0.5s;
  transition-delay: ${({ open }) => open ? '0s' : '0.5s;'};
  overflow: hidden;
`

type MenuProps = {
  open: boolean,
}

const Menu: FunctionComponent<MenuProps> = ({ open, children }) => {
  return (
    <StyledMenu open={open}>
      <MenuItems open={open}>{children}</MenuItems>
    </StyledMenu>
  )
}

export default Menu