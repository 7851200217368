import { FunctionComponent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import Burger from './Burger'
import Menu from './Menu'
import { COLORS } from '../../constants';

export enum Style {
    Light = 1,
    Dark,
}

const StyledMenuContainer = styled.div`
    background: #fff;   
    z-index: 10;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    a {
        text-decoration: none;
    }
`

const StyledTitle = styled.h1`
    margin: auto;
    text-align: center;
    font-weight: 300;
    z-index: 1;
    text-transform: uppercase;
    color: ${COLORS.dark};

    padding-top: 16px;

    ${down('md')} {
        padding-top: 16px;
        font-size: 22px;
    }

    ${up('md')} {
        font-size: 22px;
    }
`

type HamburgerMenuProps = {
    title: string
    style: Style
}

export const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = ({ title, style, children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const node = useRef<HTMLDivElement>(null);
    return (
        <StyledMenuContainer ref={node}>
            {/* Only show page title for the dark version */}
            <Burger open={open} setOpen={setOpen} style={style} />
            {(style === Style.Dark && !open) && <Link to={"/"}><StyledTitle>{title}</StyledTitle></Link>}
            <Menu open={open}>{children}</Menu>
        </StyledMenuContainer>
    )
}