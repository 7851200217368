
import { FunctionComponent } from "react";
import ImageGallery from '../components/ImageGallery/ImageGallery';
import { Series } from '../cms/schema';
import Container from '../pages/fragments/Container';

type GalleryPageProps = {
    title: string
    series: Array<Series>
}

const GalleryPage: FunctionComponent<GalleryPageProps> = ({ title, series }: GalleryPageProps) => {
    return (
        <Container>
            <ImageGallery title={title} series={series} />
        </Container>
    )
}

export default GalleryPage