import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

type StyledSubeMenuProps = {
    open: boolean
}

const StyledSubeMenu = styled.li<StyledSubeMenuProps>`
    span.text {
        font-size: 2em;
    }

    ul {
        list-style: none;
        margin-left: -25px;
        display: ${({ open }) => open ? 'block' : 'none'};
        transition: 0.5s;
    }

    :hover {
        cursor: pointer;
    }
`

type SubMenuProps = {
    text: string
}

const SubMenu: FunctionComponent<SubMenuProps> = ({text, children}) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <StyledSubeMenu open={open} onClick={() => setOpen(!open)}>
            <span className="text">{text}</span>
            <ul className="submenu-item">
                {children}
            </ul>
        </StyledSubeMenu>
    )
}

export default SubMenu