import styled from 'styled-components';
import { Page } from '../cms/schema';
import TextPage from './TextPage';

const StyledVideoContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 20px;
`

const VideoEmbedHTML = `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/589215731?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=1669b43f70" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Drawing Workshop"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`

type MentoringPageProps = {
    page: Page | undefined
}

const MentoringPage = ({ page }: MentoringPageProps): JSX.Element => {
    return (
        <TextPage key={"mentoring"}>
            <h2>{page?.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: page?.content ? page.content : "" }}></div>
            <StyledVideoContainer dangerouslySetInnerHTML={{ __html: VideoEmbedHTML }}></StyledVideoContainer>
        </TextPage >
    )
}

export default MentoringPage
