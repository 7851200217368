import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import { Series } from '../../cms/schema';
import { COLORS } from '../../constants';

const StyledWrapper = styled.div`
    h2 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 300;
    }
`

const StyledContainer = styled.div`
    min-height: 68vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const StyledItem = styled.div`
    margin-top: 20px;
    flex: 50%;

    a {
        color: ${COLORS.dark};
        text-decoration: none;
    }
    
    h3 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 9pt;
    }

    img {
        ${down('lg', 'landscape')} {
            max-width: 35vw;
        }
        ${down('lg', 'portrait')} {
            max-width: 84vw;
        }
        ${up('lg')} {
            max-width: 22vw;
        }
    }
`

type ImageGalleryProps = {
    title: string
    series: Array<Series>
}

const ImageGallery: FunctionComponent<ImageGalleryProps> = ({ title, series }: ImageGalleryProps) => {
    return (
        <StyledWrapper>
            <h2>{title}</h2>
            <StyledContainer>
                {series.map(s =>
                    <StyledItem key={s.linkName}>
                        <Link to={`/series/${s.linkName}`}>
                            <img alt={""} src={s.coverImage?.fallbackImageUrl} />
                            <h3>{s.name}</h3>
                        </Link>
                    </StyledItem>
                )}
            </StyledContainer>
        </StyledWrapper>

    )
}

export default ImageGallery