import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Style } from './HamburgerMenu';
import { COLORS } from '../../constants';

const StyledBurger = styled.div`
  position: fixed;
  top: 20px;
  z-index: 5;
  margin: auto;
  right: 5vw;

  :hover {
    cursor: pointer;
  }
`

interface StyledBurgerLineProps {
  open: boolean
  style: Style
}

const StyledBurgerLine = styled.span<StyledBurgerLineProps>`
  position: absolute;
  width: 15px;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  display: block;
  ${({ style, open }) => (style === Style.Dark && !open) && 'background: ' + COLORS.dark + ';'}
  ${({ style, open }) => (style === Style.Light || open) && 'background: #fff;'}
  transition: 0.5s;

  :first-child {
    top: 4px;
    ${({ open }) => open && 'top: 50%;'};
    ${({ open }) => open && 'transform: rotate(405deg);'};
  }

  :nth-child(2) {
    ${({ open }) => open && 'top: 50%;'};
    opacity: ${({ open }) => open ? '0' : '1'};
  }

  :last-child {
    top: 16px;
    ${({ open }) => open && 'top: 50%;'};
    ${({ open }) => open && 'transform: rotate(-405deg);'};
  }
`

type StyledMenuTextProps = {
  style: Style
  open: boolean
}

const StyledMenuText = styled.span<StyledMenuTextProps>`
  z-index: 5;
  letter-spacing: 1px;
  padding-left: 18px;
  text-transform: uppercase;

  ${({ style, open }) => (style === Style.Dark && !open) && 'color: ' + COLORS.dark + ';'}
  ${({ style, open }) => (style === Style.Light || open) && 'color: #fff;'}

  :hover {
      cursor: pointer;
  }
`

type BurgerProps = {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  style: Style
}

const Burger: FunctionComponent<BurgerProps> = ({ open, setOpen, style }: BurgerProps) => {
  return (
    <StyledBurger onClick={() => setOpen(!open)} id={"menu"}>
      <StyledMenuText open={open} style={style}>Menu</StyledMenuText>
      <div>
        <StyledBurgerLine open={open} style={style} />
        <StyledBurgerLine open={open} style={style} />
        <StyledBurgerLine open={open} style={style} />
      </div>
    </StyledBurger>
  )
}

export default Burger