
import {
    Switch,
    Route,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import { Series } from '../cms/schema';
import { getSeriesByLinkName } from '../cms/selectors';
import SeriesPage from '../pages/SeriesPage';
import TextPage from '../pages/TextPage';

interface SeriesRouteParams {
    linkName: string
}

type SeriesRouteProps = {
    allSeries: Array<Series>
}

const SeriesRoute = ({ allSeries }: SeriesRouteProps) => {
    const { linkName } = useParams<SeriesRouteParams>();

    const series: Series | undefined = getSeriesByLinkName(allSeries, linkName);

    if (!series) {
        return (
            <TextPage key={"notfound"}>
                <h2>Not found</h2>
            </TextPage>
        )
    }

    return (
        <SeriesPage series={series} />
    )
}

type SeriesRouterProps = {
    allSeries: Array<Series>
}

const SeriesRouter = ({ allSeries }: SeriesRouterProps) => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/:linkName`}>
                <SeriesRoute allSeries={allSeries} />
            </Route>
        </Switch>
    )
}

export default SeriesRouter