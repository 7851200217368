import React from "react";
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import { Style } from '../components/HamburgerMenu/HamburgerMenu';
import backgroundImage from './images/backgrounds/home.jpg';
import Menu from './fragments/Menu';

const HomeBackground = styled.div`
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`

const HomeBackgroundTitle = styled.h1`
  top: 85vh;
  position: absolute;
  left: 0; bottom: 0; right: 0;
  margin:auto;
  max-width: 50vw;

  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;

  ${down('md')} {
    top: 75vh;
    font-size: 20px;
  }

  ${up('md')} {
    font-size: 26px;
  }
`

const Home = () => {
  return (
    <React.Fragment>
      <Menu style={Style.Light} />
      <HomeBackground>
        <HomeBackgroundTitle>Tony Hull</HomeBackgroundTitle>
      </HomeBackground>
    </React.Fragment>
  )
}

export default Home