import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { up, down, between } from 'styled-breakpoints';


type StyleMenuItemsProps = {
    open: boolean
}

const StyleMenuItems = styled.ul<StyleMenuItemsProps>`
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;

    ${down('md', 'landscape')} {
        top: 15vh;
        max-width: 26vw;
        font-size: 11px;
    }

    ${down('md', 'portrait')} {
        top: 15vh;
        max-width: 64vw;
    }

    ${between('md', 'lg')} {
        font-size: 22px;
        max-width: 45vw;
    }

    ${up('lg')} {
        top: 10vh;
        max-width: 25vw;
        font-size: 22px;
    }

    li {
        opacity: ${({ open }) => open ? '1' : '0'};
        transition: 0.5s;
        transition-delay: ${({ open }) => open ? '0.5s' : '0s'};
    }

    span {
        text-transform: uppercase;
        color: #fff;    
        font-weight: 200;
        font-family: sans-serif;
        letter-spacing: 5px;
        display: block;
        padding: 5px;
    }

    a {
        text-decoration: none;
    }


`

type MenuItemsProps = {
    open: boolean
}

const MenuItems: FunctionComponent<MenuItemsProps> = ({ open, children }) => {
    return (
        <StyleMenuItems open={open}>{children}</StyleMenuItems>
    )
}

export default MenuItems