import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { up, down, between } from 'styled-breakpoints';
import nextArrowImage from './images/next.png';
import backArrowImage from './images/back.png';

export type Image = {
    fallbackImageUrl: string
    imageSizes: Array<ImageWithWidth>
    caption?: JSX.Element
    alt: string
}

export type ImageWithWidth = {
    url: string
    width: number
}

const ImageDimensions = {
    Desktop: {
        MaxWidth: 60,
        MaxHeight: 62,
    },
    Tablet: {
        MaxWidth: 84,
        MaxHeight: 40,
    },
    Mobile: {
        Portrait: {
            MaxWidth: 84,
            MaxHeight: 35,
        },
        Landscape: {
            MaxWidth: 84,
            MaxHeight: 56,
        },
    },
}

const StyledImageSlider = styled.div`
    ${down('md')} {
        padding-bottom: 25px;
    }

    ${down('lg')} {
        padding-bottom: 15px;
    }
`

const StyledImageContainer = styled.div`
    display: flex;
    justify-content: center;
`

const StyledArrowsContainer = styled.div`
    position: absolute;

    ${down('lg')} {
	    margin-top: 55px;
        padding-bottom: 20px;
        left: 43vw;
        width: 12vw;
    }

    ${up('xl')} {
        top: ${7 + ImageDimensions.Desktop.MaxHeight - ImageDimensions.Desktop.MaxHeight / 2}vh;
        right: 10vw;
        width: 80vw;
        display: flex;
        justify-content: space-between;
        bottom: 55vh;
    }
`

const StyledArrow = styled.div`
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 

    ${down('md')} {
        width: 8px;
        height: 10px;
    }
    ${up('md')}{
        width: 24px;
        height: 30px;
    }
    cursor: pointer;

    &.back {
        background: url(${backArrowImage}) no-repeat;
        background-size: contain;
        ${down('lg')} {
            float: left;
        }
    }

    &.next {
        background: url(${nextArrowImage}) no-repeat;
        background-size: contain;
        ${down('lg')} {
            float: right;
        }
    }

    &.inactive {
        opacity: 0;
        ${down('md')} {
            opacity: 0.5;
        }
    }
`

const StyledImage = styled.img`
    position: absolute;
    width: auto;
    height: auto;

    ${down('md', 'portrait')} {
        max-height: ${ImageDimensions.Mobile.Portrait.MaxHeight}vh;
        max-width: ${ImageDimensions.Mobile.Portrait.MaxWidth}vw;
    }

    ${down('md', 'landscape')} {
        max-height: ${ImageDimensions.Mobile.Landscape.MaxHeight}vh;
        max-width: ${ImageDimensions.Mobile.Landscape.MaxWidth}vw;
    }

    ${between('md', 'lg')} {
        max-height: ${ImageDimensions.Tablet.MaxHeight}vh;
        max-width: ${ImageDimensions.Tablet.MaxWidth}vw;
    }

    ${up('lg')} {
        max-height: ${ImageDimensions.Desktop.MaxHeight}vh;
        max-width: ${ImageDimensions.Desktop.MaxWidth}vw;
    }

    /* iPad Pro Portrait */
    @media only screen
    and (min-width: 1024px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
        max-height: ${ImageDimensions.Tablet.MaxHeight}vh;
        max-width: ${ImageDimensions.Tablet.MaxWidth}vw;
    }

    &.active {
        visibility: visible;
        transition: opacity 1s ease-in-out;
    }
    &.previous {
        visibility: visible;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
    &.inactive {
        opacity: 0;
    }
`

const StyledImageMetadataContainer = styled.div`
    // Ensure the image metadata (caption) is positioned below the image
    ${down('md', 'portrait')} {
        padding-top: ${ImageDimensions.Mobile.Portrait.MaxHeight}vh;
    }

    ${down('md', 'landscape')} {
        padding-top: ${ImageDimensions.Mobile.Landscape.MaxHeight}vh;
    }

    ${between('md', 'lg')} {
        padding-top: ${ImageDimensions.Tablet.MaxHeight}vh;
    }

    ${up('lg')} {
        padding-top: ${ImageDimensions.Desktop.MaxHeight}vh;
    }

    /* iPad Pro Portrait */
    @media only screen
    and (min-width: 1024px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
        padding-top: ${ImageDimensions.Tablet.MaxHeight}vh;
    }

`

const StyledImageMetadata = styled.div`
    display: flex;
    justify-content: center;

    // Font sizes for mobile vs. desktop
    ${down('md')} {
        font-size: 12px;
    }

    ${up('md')} {
        font-size: 13px;
    }
    
    p {
        position: absolute;
        max-width: 84vw;

        &.active {
            visibility: visible;
            transition: opacity 1s ease-in-out;
        }
        &.previous {
            visibility: visible;
            opacity: 0;
            transition: opacity 1s ease-in-out;
        }
        &.inactive {
            opacity: 0;
        }
    }
`

type TouchLocation = {
    x: number
    y: number
}

type ImageCarouselProps = {
    images: Array<Image>
}

const ImageCarousel: FunctionComponent<ImageCarouselProps> = ({ images }: ImageCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [previousIndex, setPreviousIndex] = useState<number | undefined>(undefined);
    const [touchStartLocation, setTouchStartLocation] = useState<TouchLocation | undefined>(undefined);

    if (!images || images.length === 0) {
        return null
    }

    const backOnClick = (): void => {
        const newIndex: number = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex)
        setPreviousIndex(currentIndex)
    }
    const nextOnClick = (): void => {
        const newIndex: number = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
        setPreviousIndex(currentIndex)
    }
    const handleTouchStartEvent = (event: React.TouchEvent): void => {
        const firstTouchEvent = event.touches[0];
        setTouchStartLocation({
            x: firstTouchEvent.clientX,
            y: firstTouchEvent.clientY
        });
    }
    const handleTouchEndEvent = (event: React.TouchEvent): void => {
        const firstTouchEvent = event.changedTouches[0];
        const location = {
            x: firstTouchEvent.clientX,
            y: firstTouchEvent.clientY
        };
        if (touchStartLocation) {
            const differences = {
                x: touchStartLocation.x - location.x,
                y: touchStartLocation.y - location.y
            };
            // Ignore swipe that involves more than 20 pixels along y axis
            if (differences.y > 20) {
                return
            }
            // Right swipe
            if (differences.x > 0) {
                nextOnClick()
            } else if (differences.x < 0) { // Left swipe
                backOnClick()
            }
        }
    }

    const classNameForIndex = (index: number): string => {
        if (index === currentIndex) {
            return "active"
        }
        if (index === previousIndex) {
            return "previous"
        }
        return "inactive"
    }
    const pieceImageSrcSet = (imageSizes: Array<ImageWithWidth>): string | undefined => {
        return imageSizes.map(image => `${image.url} ${image.width}w`).join(",")
    }

    return (
        <StyledImageSlider>
            <StyledImageContainer>
                {images.map((image, index) => <StyledImage key={index} onTouchStart={handleTouchStartEvent} onTouchEnd={handleTouchEndEvent} src={image.fallbackImageUrl} srcSet={pieceImageSrcSet(image.imageSizes)} alt={image.alt} className={classNameForIndex(index)} />)}
            </StyledImageContainer>
            <StyledImageMetadataContainer>
                <StyledImageMetadata>
                    {images.map((image, index) => image.caption && <p key={index} className={classNameForIndex(index)}>{image.caption}</p>)}
                </StyledImageMetadata>
            </StyledImageMetadataContainer>
            <StyledArrowsContainer>
                <StyledArrow className={"back"} onClick={backOnClick}></StyledArrow>
                <StyledArrow className={"next"} onClick={nextOnClick}></StyledArrow>
            </StyledArrowsContainer>
        </StyledImageSlider>
    )
}

export default ImageCarousel