import { HamburgerMenu, Style } from '../../components/HamburgerMenu/HamburgerMenu';
import SubMenu from '../../components/HamburgerMenu/SubMenu';
import SubMenuItem from '../../components/HamburgerMenu/SubMenuItem';
import MenuItem from '../../components/HamburgerMenu/MenuItem';

type MenuProps = {
    style: Style
}

const Menu = ({ style }: MenuProps) => {
    return (
        <HamburgerMenu title={"Tony Hull"} style={style}>
            <SubMenu text="Current">
                <SubMenuItem path="/current/painting" text="Painting" />
                <SubMenuItem path="/current/drawing" text="Drawing" />
            </SubMenu>
            <SubMenu text="Archive">
                <SubMenuItem path="/archive/painting" text="Painting" />
                <SubMenuItem path="/archive/drawing" text="Drawing" />
            </SubMenu>
            <MenuItem path="/writing" text="Writing" />
            <MenuItem path="/bio" text="Bio" />
            <MenuItem path="/mentoring" text="Mentoring" />
            <MenuItem path="/podcast" text="Podcast" />
            <MenuItem path="/contact" text="Contact" />
        </HamburgerMenu>
    )
}

export default Menu