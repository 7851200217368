import { Redirect } from 'react-router-dom';
import { Series } from '../cms/schema';
import GalleryPage from '../pages/GalleryPage';
import { getSeriesByTypeAndStatus, getSeriesUrl } from '../cms/selectors';

type GalleryRouterProps = {
    allSeries: Array<Series>
    title: string
    type: string
    current: boolean
}

const GalleryRouter = ({ allSeries, title, type, current }: GalleryRouterProps) => {
    // Get all matching series that contain artwork
    const series: Array<Series> = getSeriesByTypeAndStatus(allSeries, type, current).filter(s => s.pieces.length > 0);

    // If there is only one series, go straight to it
    if (series.length === 1) {
        return <Redirect to={getSeriesUrl(series[0])} />;
    }

    return (
        <GalleryPage title={title} series={series} />
    )
}

export default GalleryRouter